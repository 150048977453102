.SectionCareers{
    /* margin: 1rem 0; */
   
    width: 100%;

   
    
    
}
.wrapperDiv{
    max-width: 1600px;
    margin: 0 auto;
    background-image: url('./images/backgroundSelf.png');
    height: 400px;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;

    align-items: center;
    justify-content: center;
}
@media(max-width:600px){
 .wrapperDiv{
    height: auto;
    background-image: none;
 }
}
.CareersDiv{
 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    background-color: white;
    min-width: 80%;
    border-radius: 1rem;  
    padding: 1rem;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%)
}
.h1Careers{
    font-weight: bold;
    text-align: center;

}
.NavLinkCareers {
    margin: 1rem 0;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    background-color: rgb(14, 144, 161);
    color: white;
    vertical-align: middle;
    padding: 1rem;
    width: 200px;
    border-radius: 4rem;
    letter-spacing: 1px;
    text-decoration: none;
    
   
}
@keyframes widthChanger {
    from { width: 200px; letter-spacing: 1px;}
    to {width: 300px;letter-spacing: 3px;}
    
}
.NavLinkCareers:hover{
    
    color: rgb(14, 144, 161);
    background-color: white;
    border : 2px solid rgb(14, 144, 161);
    letter-spacing: 2px;
    animation: widthChanger 2s forwards cubic-bezier(0.075, 0.82, 0.165, 1) ;
}