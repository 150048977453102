.TamilNadu:hover{
    fill:black;
}
.MapSVgDiffe{
  
    padding-top: 3rem ;
    aspect-ratio: 960/300;
    width: 100%;
    background-repeat: no-repeat ;
    background-position: center;
    background-size: cover;
}
.spacerM{
    background-image: url('./components/utils/images/layered-waves-haikei.svg');

}