
.box{
   
   
    width: 100%;
    
 

}
.navTopNav{
    max-width: 1600px;
    margin: 0 auto;
    
 
}
.logoImage{
    padding: 0.25rem;
    width: 300px;
    height: 100%;
}
.ParentDivTopNav{
 
   
    display: flex;
    justify-content: space-between;
    align-items: center;
  
}
.divTopNav{
    padding-top: 2px;
    padding: 0 1rem;
    margin : 0 2rem;
}
.divTopNavA{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.IconSpan{
    text-align: center;
    /* border: 2px dotted #367ce5; */
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    
   
}
.IconSpan svg path{
    fill: #367ce5;
}
@media(max-width:600px){
    .divTopNavA{
        padding: 0 0.25rem;
        margin: 0;
        display: none;
    }
    .divTopNav{
        display: flex;
        justify-content: center;
    }
    .ParentDivTopNav{
        justify-content: center;
        flex-direction: column;
    }
    .logoImage{
        padding: 1rem;
        width: 80%;
        text-align: center;
    }
  
}

@media(max-width:800px){
    .divTopNav{
        padding: 0 0.25rem;
        margin: 0;
    }
}