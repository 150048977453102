@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

*{
   
    font-family: 'Poppins', sans-serif !important;
    box-sizing: border-box;
    

}
body{
    margin: 0 !important;
    padding: 0 !important;
    
}
::-webkit-scrollbar-track {
    background-color: inherit;
  
  }
 
.wave{
    padding-top: 3rem ;
    aspect-ratio: 960/300;
    width: 100%;
    background-repeat: no-repeat ;
    background-position: center;
    background-size: cover;
   

}
.spacer{
    background-image: url('./components/utils/images/wave-haikei.svg');
    background-color: #ffff;
}



.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='black' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
   }
   
   .carousel-control-next-icon {
     background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='black' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
   }

   .carousel-indicators {
    display: none;
   }
   

   .gallery{
    border-radius: 2rem !important;
   }

   .galleryDiv{


  
    padding: 1rem;
   }
   .galleryDiv img{
    width: 100%;
    display: flex;
    align-items: center;
   }

   .slick-slider .slick-track {
    display: flex;
    align-items: center;
   }

   .slick-slide img{
    border: none;
   }


   .main{
    width: 100%;
    
    margin: 0 auto;
   
}

.WrapperDiv{
    max-width: 1600px;
  
    margin : 0 auto;
    padding-left: 3rem;
 
}
.jobDescription{
    padding: 2rem;
}
.jobDescription p{
    text-align: left;
    font-weight: bold;
}
.ulJobDescription{
    padding-left: 1rem  !important;
}
.ulJobDescription li {
    margin: 1rem 0 ;
    text-align: justify;
  
}

@media(max-width:600px){
    .ds {
        display: flex!important;
      
        flex-direction: column!important;
        justify-content: center!important;
        align-items: center!important;
        text-align: center;
    }
    .div_app{
        width: 100%;
        padding: 0 1rem;
    }
    .galleryDiv{
        width: 100%;
    }
    .gallery{
        border-radius: 0.75rem !important;
       }
    
}
@media(max-width:880px){
    .WrapperDiv{
        
        padding-left: 0;
     
    }
}
@media (max-width:1044px) {
   
    .row{
        flex-direction: column !important;
    }
    .col-md-3{
        width: auto !important;
    }
    .ds {
        display: flex!important;
        width: 100%;
        flex-direction: column!important;
        justify-content: center!important;
        align-items: center!important;
        text-align: center;
    }
}
