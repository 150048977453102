html {
    box-sizing: border-box ;
    
}

*, *:before, *:after {
    box-sizing: inherit ;
}
.navContainerMobileFinmap{
    background-color: rgb(10, 43, 80);

    width: 100%;
    height: 3rem;
    overflow: hidden;
    display: flex;
    justify-content: right;
    margin: 0 !important;
    

}
.CloseButton{
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
    border: none;
    background-color: transparent;
    color: white;
    position: fixed;
    font-family: Arial, Helvetica, sans-serif!important;
   

}
.Logo{
    
    padding: 0 0.45rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    
}
.bar1,
.bar2,
.bar3 {
    width: 25px;
    height: 3px;
    background-color: rgb(242, 233, 233);
    display:block;
    margin: 2px;
}
.ContentNavBarMobile{
    background-color: rgb(16, 68, 127);
    width:100% ;
    
    position: fixed;
    top: 0;
    right: 0;
     height: 100%;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    z-index: 4;
    overflow: hidden!important;
    padding-top: 4rem;
    align-items: center;

}

.navul{
    width: 100%;
    /* text-align: center; */
    text-decoration: none;
    list-style:none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0!important;
    padding-left: 0!important;
    
}
.navli{
    margin-top: 1rem;
    padding: 0 1.5rem ;
    width: 80%;
    margin: 2rem;
   
}
.navli a svg{
    margin-right: 1rem;
}
.navli:after{
   
    display:block;
    content: '';
    border-bottom: solid 3px #f3f4f4;  
    transform: scaleX(0);  
    transition: transform 250ms ease-in-out;
    /* margin-right: 2rem; */
}
.navli:hover:after { transform: scaleX(1); }
.navli.fromRight:after{ transform-origin:100% 50%; }
.navli.fromLeft:after{  transform-origin:  0% 50%; }
@keyframes animateBorder {
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(100%);
    }
    
}
.navli a {
    font-size: 2rem;
    text-decoration: none;
    color: white;
}
.divTopNav{
    display: flex;
    justify-content: center;
}
.logoImage{
    padding: 0.25rem;
    width: 200px;
    height: 100%;
}