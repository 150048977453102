.navContainerFinmap{
    background-color: #0a2b50;

    width: 100%;
    height: 3rem;
    overflow: hidden;
    margin: 0 !important;

}
.navFinmapSol{
    
    max-width: 1600px;
    margin: auto;
    display: flex;
    justify-content: space-between;

    align-items: center;
    
}
.navul{

    display: flex;
    list-style: none;
   
    align-items: center;
    
  
}
.navul li{
    margin : 0 0.5rem;
    padding:  0.75rem;
    
}


.navli a{
  text-decoration: none !important;
  color : white;
}
.buttonLogin{
    padding: 1rem;
    background-color: rgb(11, 182, 168);
    color: black;
    float: right;
    
    border: none;
    border-radius: 20%;
    box-sizing: border-box;
}
.buttonLogin a{
    color: rgb(3, 35, 65);
    text-decoration: none;
   
    padding: 0.15rem 1.25rem;
    
}
.navli:hover  

{
    background-color: white;
    color: rgb(3, 35, 65);
   
    overflow: hidden !important;
}
.navli:hover a {
    color: inherit;
}

.navli a:hover{
    color: rgb(3, 35, 65);
}
.navLink svg{
    margin-right: 0.75rem;
}