.OurPartnersSection{

    width: 100%;
    display:  flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.OurPartnersSectionDiv{
    display: flex;
    justify-content: center;
    width: 33%;
    margin: 1rem 0;
}
.OurPartnersSectionDiv img{
    width: 80%;
}

.OurPartnersSectionDiv:hover{
   cursor: pointer;
}

@media(max-width:600px){
    .OurPartnersSectionDiv {
        width: 100%;
    }
}