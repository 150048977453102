.footerDiv{
    width: 100%;
    background-color: #0a2b50;
}
footer{
    max-width: 1600px !important;
    margin: 0 auto;
    
}
.footerLi{
    margin: 1rem 0;
    
}
.fLi{
    margin: 1rem 0;
}
.fLi svg{
    /* margin-right: 0.75rem; */
    font-size: 1.75rem;
   
}

.footerP{
    display: inline;
    margin-left:1rem;
    line-height: 1.75rem;
    
}
.footerLi a{
    
 text-decoration: none;
 color: white;
}
.HR {
    color: white;
    background-color: white;
}

.bd{
    text-align: center;
    height: 4px;
    width: 30%;
  border-radius: 5rem;
    background-color: white;
    margin: 1rem 0;

}

 @media (max-width:800px) {
   
    .row{
        flex-direction: column !important;
    }
}
