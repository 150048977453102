/* Important Cards CSS */
.ImportantCards{
    background-color: #fff;
    width: 350px;
    height: 480px;
  padding: 1rem;
    margin: 1rem;
    padding-top: 0.5rem;
    text-align: center;
    box-shadow: 0 5px 35px rgb(0 0 0 / 10%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.h5Services{
    font-weight: 700;
    color: #2962a3;
}
.iconBox{
    width: 100%;
    height: 100%;
}
.iconBox img{
    width: 100%;
    height: 100%;
}


/*    the section Styling */
.LowerSection{
    background-color: #ffff;
    
    width: 100%;
   
}
.LowerSectionDiv{
    padding: 1rem;
    display: flex;
    max-width: 1600px;
    margin : 0 auto;
    justify-content: center;
}
.CenterLowerDiv{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.HeaderOurPartners h1 {
font-weight: bold;
}

.wave{
    padding-top: 3rem ;
    aspect-ratio: 960/300;
    width: 100%;
    background-repeat: no-repeat ;
    background-position: center;
    background-size: cover;
   

}
.spacer{
    background-image: url('../../components/utils/images/wave.svg');
    background-color: #ebf1f5;
}
