.sectionStyle{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  
 
}


.wrapperDiv{
    width: 50%;
}
.formWrapper{
    padding: 2rem;
    width: 50%;

    
}
.buttonApplyDynamic{
    width: 200px !important;
    background-color: rgb(9, 72, 98);
    color: white;
    font-size: larger;
    margin-top: 3rem;
}

.disabled{
    width: 200px !important;
    background-color: rgba(137, 173, 189, 0.5);
    color: white;
    font-size: larger;
    margin-top: 3rem;
    pointer-events: none;
    cursor:not-allowed !important;
    border: none;
}
.wrapperH h1{
    font-weight: bolder;
}
.formWrapper {
  margin: 0 auto;
}
.formAction input, .formAction textarea{
    display: block;

    width: 100%;
    border-radius: 0.25rem;
    line-height: 3rem;
    border:  2px solid white;
    margin: 2rem auto;
    box-shadow: 10px 10px 10px rgb(0 0 0 / 0.5);
}
/* .formAction input[type= 'submit']{
    background-color: rgb(55, 171, 206);
    color: white;
    border: none;
  
    width: 25%;
    font-size: 1.25rem;
} */
.formAction input::placeholder, .formAction textarea::placeholder{
    padding: 1rem;
}
@media(max-width:1042px){
    .wrapperDiv , .formWrapper{
        width: 100%;
    }
}


.waveR{
    padding-top: 3rem ;
    aspect-ratio: 960/300;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat ;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
   
   

}
.spacerR{
    background-image: url('../../components/utils/images/WaveBelow.svg');
    background-color: #ffff;

}