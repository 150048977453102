.serviceSection{
 
    width: 100%;
    background-color: #fefefe;
    z-index: 2;
    padding-top: 4rem;
}
.ServicesCardDiv{
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
}
.CoverdivServices{
    max-width: 1600px;
 
    padding: 1rem;
    margin: 0 auto;
}
.servicesDiv{
    padding: 0.5rem;
    display: flex;
    justify-content: center;

}
.hServices{
    font-weight: 700;
    color: white;
    
    
}
.h5Services{
    font-weight: 700;
    color: #2962a3;
}
.Services{
    background-color: #fff;
    width: 25%;
    /* height: 400px; */
    padding: 1rem;
    margin: 2rem 2rem;
    padding-top: 1rem;
    text-align: center;
    box-shadow: 10px 10px 10px rgb(0 0 0 / 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    border: 1px solid rgba(110, 109, 107,0.5);

    
}
.Services:hover{
    background-color: #2962a3;
    color: white;
    cursor: pointer;

}
.Services:hover .iconBoxShadow{
    background-color: #bdc3c8;
    border: 2px solid #bdc3c8;
}
.Services:hover  .h5Services {
    color: yellow;
}

.Services:hover > .iconBox{
        animation: rotation 0.6s ease-in forwards;
}
.Services:hover .NavLinkServices{
    color: white;
}

.iconBoxShadow{
    background-color: #6b6f72;
    width: 75px;
    height: 12px;
    border: 2px solid #6b6f72;
    border-radius: 50%;
    margin-top: 0.25rem;
    margin-bottom: 2rem;

}
@keyframes rotation {
    from{transform: rotate(0deg)}
    to{transform: rotate(-10deg);}
    
}
.iconBox{
    width: 300px;
    /* height: 50%; */
    transform: rotate(0deg);
    /* margin-bottom: 1rem; */
   
    /* border-radius: 50%; */
   
    padding: 2rem;
}

.NavLinkServices{

    color: rgb(228, 0, 0);
    font-size: 1.3rem;
    text-decoration: none;

    display: inline-block;
    font-weight: bold;
    padding: .5em;
    margin-left: -.5em;
    position: relative;
    clip-path: polygon(0 0 ,100% 0, 100% 100%, 0% 100%);

}
.NavLinkServices::before{
    position: absolute;
    content: '';
    border-top: 3px solid rgb(167, 233, 36);
    border-radius: 1em;
    bottom: .3em;
    width: 1em;
    transform-origin: left;
    transition: transform .5s cubic-bezier(0.075, 0.82, 0.165, 1);


}
.summary{
    margin-top: 1rem;
}
.NavLinkServices::after{
    position: absolute;
    content: '';
    border-bottom: 3px solid rgb(255, 255, 255);
    border-radius: 1em;
    bottom: .3em;
    width: 82%;
    left: 1em;
    transform: translateX(110%);
    transition: transform .5s cubic-bezier(0.075, 0.82, 0.165, 1);



}
.NavLinkServices:hover::before{
    transform: scaleX(0.3);
}
.NavLinkServices:hover::after{
    transform: translateX(0);
}

.iconBox img{
    width: 100%;
    height: 100%;
    
}

.disabledA{
    background-color: #d3d9de;
    user-select: none!important;
   
    pointer-events: none;
    /* background-color: #2962a3; */
    
    
}

.comingSoon h1{
    width: 100%;
    height: 100%;
    /* position: absolute; */
    /* top: 0; */
  
    /* transform: rotateZ(-55deg); */
    color: red;
    z-index: 3;
    margin-top: 1rem;
}

@media (max-width:1400px) {
    .Services{
        width: 25%;
    }
    
}

@media (max-width:1098px) {
    .Services{
        width: 40%;
    }
    
}

@media (max-width:674px) {
    .Services{
        width: 100%;
    }
    .iconBox{
        width: 100%;
    }
    
}